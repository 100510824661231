import React, { useContext } from "react"
import { Link } from "gatsby"
import {
  Hits,
  Index,
  Snippet,
  Highlight,
} from "react-instantsearch-dom"
import styled, { css } from "styled-components"
import { StyledPostCard } from "../../Posts/PostCard"
import { SearchContext } from "../../../context/search"
import { theme } from "../../Shared/styles-global"
import { setThemeVars } from "../../../utils/theme-helper"

const PageHit = ({ hit }) => {
  const { setIsSearch } = useContext(SearchContext)

  return (
    <Link
      onClick={() => setIsSearch(false)}
      to={hit.slug}
    >
      <StyledPostCard>
        <h3>
          <Highlight attribute="title" hit={hit} tagName="mark" />
        </h3>
        <p>
          <Snippet attribute="excerpt" hit={hit} tagName="mark" />
        </p>
      </StyledPostCard>
    </Link>
  )
}

const SearchResults = ({ indices, className, show }) => (
  <ResultsWrapper className={className} show={show}>
    {indices.map(index => (
      <Index indexName={index.name} key={index.name}>
        <Hits className="Hits" hitComponent={PageHit} />
      </Index>
    ))}
  </ResultsWrapper>
)

export default SearchResults

const ResultsWrapper = styled.div`
  ${({ show }) => !show && css`
    display: none;
  `}

  li {
    list-style: none;
  }

  .ais-Highlight-highlighted,
  .ais-Snippet-highlighted {
    background-color: Transparent;
    text-decoration: underline ${() => setThemeVars(theme.darkColor, theme.lightGreyColor)};
    color: ${() => setThemeVars(theme.darkColor, theme.lightGreyColor)};
  }
`
