import React, {
  useContext,
  useState,
  useEffect,
} from "react"
import styled, { css } from "styled-components"
import Profile from "../Profile"
import Tags from "../Tags"
import Search from "../Search"
import PostList from "../Posts/PostList"
import Loader from "../Loader"
import ToggleMode from "../Layout/ToggleMode"
import { isMobile } from "react-device-detect"
import { TAG } from "../../constants"
import { SearchContext } from "../../context/search"

const searchIndices = [{ name: `Pages`, title: `Pages` }]

const MainCard = ({ posts, loads }) => {
  const { isSearch } = useContext(SearchContext)
  const [selectedTag, setSelectedTag] = useState(TAG.ALL)
  const [filteredPosts, setFilteredPosts] = useState([])
  const [tags, setTags] = useState([])

  useEffect(() => {
    // Get current viewing tag from storage
    let curTag = sessionStorage.getItem("curTag") || TAG.ALL
    const tagExists = checkTag(curTag, posts)
    // If saved tag in storage doesn't exist among posts, set to "all"
    if (!tagExists) {
      curTag = TAG.ALL
    }

    setSelectedTag(_ => {
      filterPosts({
        posts,
        setFilteredPosts,
        selectedTag
      })
      filterTags(posts, setTags)

      return curTag
    })
  }, [
    posts,
    selectedTag,
    setFilteredPosts,
    setTags
  ])

  const handleSelectTag = tag => {
    // Save current tag in storage
    sessionStorage.setItem("curTag", tag)
    setSelectedTag(tag)
    filterPosts({
      posts,
      setFilteredPosts,
      selectedTag
    })
  }

  return (
    <StyledMainCard className="main-card">
      <StyledSwitchContainer className="switch-container">
        <ToggleMode />
      </StyledSwitchContainer>
      <StyledSubMain className="sub-main">
        <StyledSubMainInner>
          <Profile home />

          {(!isSearch && filteredPosts.length > 0) && (
            <StyledTagsPosts>
              <Tags
                selectedTag={selectedTag}
                selectTag={handleSelectTag}
                tags={tags}
              />
              <PostList
                posts={filteredPosts.slice(0, loads)}
              />
            </StyledTagsPosts>
          )}

          {isSearch && (
            <StyledTagsPosts>
              <Tags
                selectedTag={selectedTag}
                selectTag={handleSelectTag}
                tags={tags}
              />
              <Search indices={searchIndices} />
            </StyledTagsPosts>
          )}

          {filterPosts.length < 1 && (
            <div style={{ textAlign: "center" }}>
              <Loader />
            </div>
          )}

        </StyledSubMainInner>
      </StyledSubMain>
    </StyledMainCard >
  )
}

export default MainCard

// Filter tags and sort them by occurrences
function filterTags(posts, setTags) {
  const tagsByFrequency = {}
  const sortedTags = []
  // Exclude about page & dummy page
  const filteredPosts = posts.filter(
    post =>
      post.node.fields.slug !== "/about/" &&
      post.node.fields.slug !== "/__do-not-remove/"
  )

  filteredPosts.forEach(post => {
    let tags = post.node.frontmatter.tags

    if (!tags) {
      // Register tag to the post if does not have any
      post.node.frontmatter.tags = ["Uncategorized"]
      tags = ["Uncategorized"]
    }

    tags.forEach(tag => {
      if (tagsByFrequency[tag]) {
        tagsByFrequency[tag] = tagsByFrequency[tag] + 1 // update frequency
      } else {
        tagsByFrequency[tag] = 1
        sortedTags.push(tag)
      }
    })
  })

  sortedTags.sort(function (a, b) {
    return tagsByFrequency[b] - tagsByFrequency[a]
  })

  setTags(sortedTags)
}

// Check if tag in storage exists
function checkTag(storageTag, posts) {
  // Input checks
  if (!storageTag) {
    return false
  }

  if (storageTag === TAG.ALL) {
    return true
  }

  for (let i = 0; i < posts.length; i++) {
    const tags = posts[i].node.frontmatter.tags
    if (tags && tags.length > 0 && tags.includes(storageTag)) {
      return true
    }
  }

  return false
}

function filterPosts({ posts, setFilteredPosts, selectedTag }) {
  const filtered = posts.filter(({ node }, i) => {
    return (
      selectedTag === TAG.ALL ||
      (node.frontmatter.tags &&
        node.frontmatter.tags.includes(selectedTag))
    )
  })

  setFilteredPosts(filtered)
}

let StyledTagsPosts = styled.div``

StyledTagsPosts = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;

  .moveToBot {
    position: sticky;
    top: 0;
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
    .tag-list {
      .tag-horizontal {
        font-size: 0.85rem;
      }
    }
  }

  .moveToBotAnimate {
    position: sticky;
    top: 0;
    transition: padding 300ms ease-in;
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
    .tag-list {
      .tag-horizontal {
        transition: font-size 300ms ease-in;
        font-size: 0.85rem;
      }
    }
  }
`

if (!isMobile) {
  StyledTagsPosts = styled.div`
    position: relative;
    display: grid;
    grid-template-columns: 0.25fr auto;
    padding: 0.5rem 1.5rem 1.5rem 1.5rem;

    @media (max-width: 500px) {
      padding: 0rem 1rem 1rem 1rem;
    }
  `
}

const StyledMainCard = styled.div`
  position: relative;
  padding: 0 1rem 1rem 1rem;

  @media (max-width: 500px) {
    padding: 0;
  }
`

const StyledSubMain = styled.div`
  position: relative;
  margin-top: 3rem;
  border-radius: 10px;

  @media (max-width: 500px) {
    margin-top: 1rem;
    border-radius: 0px;
  }
`

const minimumContentHeight = css`
  min-height: calc(100vh - 130px);

  @media (min-width: 500px) {
    min-height: calc(100vh - 176px);
  }

  @media (min-width: 681px) {
    min-height: calc(100vh - 188px);
  }
`

const StyledSubMainInner = styled.div`
  ${({ children }) => children[1]
    ? minimumContentHeight
    : css`min-height: 100vh;`
  }
  
  ${() => !isMobile && css`
    transform: translateY(-25px);
  `}
`

const StyledSwitchContainer = styled.div`
  position: absolute;
  text-align: end;
  margin: 0 0.4rem;
  top: 12px;
  right: 25px;
  z-index: 2;

  @media (max-width: 500px) {
    right: 10px;
  }
`
