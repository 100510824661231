import { useEffect } from "react"

const events = [`mousedown`, `touchstart`]

export default (ref, onClickOutside) => {
  const isOutside = element => (
    (!ref.current && !isSearchButton(element)) || 
    (!ref.current.contains(element) && !isSearchButton(element))
  ) 

  const onClick = event => {
    if (isOutside(event.target)) {
      console.log('hey', ![...event.target.classList].includes('fa-search'), event.target)
      onClickOutside()
    }
  }

  useEffect(() => {
    for (const event of events) {
      document.addEventListener(event, onClick)
    }

    return () => {
      for (const event of events) document.removeEventListener(event, onClick)
    }
  })
}

function isSearchButton(element) {
  return (
    [...element.classList].includes("fa-search") ||
    [...element.classList].includes("search-icon")
  )
}
