import React, {
  useState,
  createRef,
  useContext,
} from "react"
import styled from "styled-components"
import algoliasearch from "algoliasearch/lite"
import { InstantSearch, SearchBox, PoweredBy } from "react-instantsearch-dom"
import SearchResults from "./SearchResults"
import useClickOutside from "./use-click-outside"
import { SearchContext } from "../../context/search"

import "./styles.scss"

const Container = styled.div``

export default function Search({ indices }) {
  const rootRef = createRef()
  const [query, setQuery] = useState()
  const [hasFocus, setFocus] = useState(false)
  const { setIsSearch } = useContext(SearchContext)
  const searchClient = algoliasearch(
    process.env.GATSBY_ALGOLIA_APP_ID,
    process.env.GATSBY_ALGOLIA_SEARCH_KEY
  )

  useClickOutside(rootRef, () => {
    setIsSearch(false)
    setFocus(false)
  })

  return (
    <div ref={rootRef}>
      <InstantSearch
        searchClient={searchClient}
        indexName={indices[0].name}
        onSearchStateChange={({ query }) => setQuery(query)}
      >
        <Container>
          <SearchBox onFocus={() => setFocus(true)} />
          <PoweredBy />
        </Container>
        <SearchResults
          show={query && query.length > 0 && hasFocus}
          indices={indices}
        />
      </InstantSearch>
    </div>
  )
}
